<template>
    <div class="content">
        <div class="content-box">
            <global-page-back
                @handlerGlobalType="handleBack"
            >
                <template #globalPageBackTabs>
                    <div class="back-detail">
                    <span>学生: {{ detailData.value.studentName }} </span>
                    <span v-if="detailData.value.studyNo">({{  detailData.value.studyNo }})</span>

                    <span style="padding: 0 20px"
                        >年级: {{ detailData.value.gradeName }}</span
                    >
                    <span>班级: {{ detailData.value.className }}</span>
                </div>
                </template>
            </global-page-back>
            <expand-filter
                :formData="formData"
                @clickBtn="clickBtn"
                @changeBtnFormType="changeBtnFormType"
                :closeWidth="360"
                @datePickerBlur="datePickerBlur"
            ></expand-filter>
            <div
                class="detail"
                :style="{height: listHeight}"
            >
                <!-- 标题 -->
                <div class="detail-title">
                    <div class="text1">实到日期详情</div>
                    <div class="text2">
                        实到天数
                        <span style="color: #3C7FFF">{{ actualDay }}</span> 天
                    </div>
                </div>
                <!-- 日期显示 -->
                <div class="detail-item">
                    <div
                        class="waibu"
                        v-for="(item, index) in monList"
                        :key="index"
                    >
                        <!-- 头部 -->
                        <div class="title">
                            {{ item.year }}年{{ item.mon }}月
                        </div>
                        <div class="box">
                            <!-- 当前月份 -->
                            <div
                                v-for="(i, index) in item.list"
                                :key="index"
                                class="box1"
                            >
                                <div
                                    class="box-data"
                                    :class="i.type ? 'box-data-selected' : ''"
                                >
                                    {{ i.day }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ExpandFilter from "../../Sub/ExpandFilter.vue";
import {CheckRecordModel} from "@/models/CheckRecord";
import GlobalPageBack from "../../Sub/GlobalPageBack/index.vue";
export default {
    name: "StudentDetail",
    props: {
        detailData: {
            type: Object,
        },
    },
    components: {
        ExpandFilter,
        GlobalPageBack
    },
    data() {
        return {
            monList: [],
            val: 3,
            // 当前日
            currentday: new Date().getDate(),
            // 当前月
            currentmonth: "",
            // 当前年
            currentyear: new Date().getFullYear(),
            startMonth: "",
            endMonth: "",
            actualDay: 0,
            listQuery: {
                onlyCode: "",
                startTime: "",
                endTime: "",
            },
            formData: {
                btnFormType: true,
                formInline: {
                    teacherName: "",
                },
                data: [
                    {
                        type: "datePick",
                        label: "",
                        key: "dateArr",
                        value: [],
                        dateType: "daterange",
                        // dateType: "datetimerange",
                        format: "yyyy-MM-dd",
                        valueFormat: "yyyy-MM-dd",
                        startPlaceholder: "开始日期",
                        endPlaceholder: "结束日期",
                        defaultTime: ["00:00:00", "23:59:59"],
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                        auth: ["user:list"],
                    },
                    {
                        type: "enquiry",
                        text: "重置",
                        fn: "reset",
                    },
                ],
            },
        };
    },
    created() {
        this.init();
    },
    computed: {
        // 当前月的天数
        danqianyue() {
            // 有3个参数，第一个是年，第二个是月，然后输入0代表当前月份   这样就可以获取天数了
            return new Date(this.currentyear, this.currentmonth, 0).getDate();
        },
        backImg() {
            return require("@/assets/images/back3.png");
        },
        listHeight() {
            return "calc(100vh - 232px)";
        },
    },
    methods: {
        /**
         * @Description: 初始化数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-11 16:07:46
         */
        init() {
            this.formData.data.forEach((item) => {
                if (item.key === "dateArr") {
                    item.value = this.detailData.dateArr;
                }
            });
            this.getList();
            // this.setDefaultTime()
        },
        getList() {
            this.listQuery.onlyCode = this.detailData.value.onlyCode;
            let data = {};
            this.formData.data.forEach((item) => {
                data[item.key] = item.value;
            });
            if (data.dateArr && data.dateArr.length > 0) {
                this.listQuery.startTime = data.dateArr[0];
                this.listQuery.endTime = data.dateArr[1];
            } else {
                this.listQuery.startTime = "";
                this.listQuery.endTime = "";
            }
            // console.log(this.listQuery, '查询参数');
            const checkRecordModel = new CheckRecordModel();
            checkRecordModel
                .getCheckRecordStudentDetail(this.listQuery)
                .then((res) => {
                    // console.log(res.data, '学生详情')
                    if (res.data.code === "200") {
                        console.log(res.data.data, "res.data.data");
                        this.actualDay = res.data.data.actualDay;
                        let checkList = res.data.data.details;
                        this.setDefaultTime(
                            this.listQuery.startTime,
                            this.listQuery.endTime,
                            checkList,
                        );
                    }
                });
        },
        /**
         * @Description: 时间日历处理
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-11 16:08:42
         */
        setDefaultTime(start, end, checkList) {
            // this.checkListTime(checkList);


            this.monList = [];
            let startTime = start.split("-");
            let endTime = end.split("-");

            if (startTime && endTime) {
                let startObj = {
                    year: Number(startTime[0]),
                    mon: Number(startTime[1]),
                };

                let endObj = {
                    year: Number(endTime[0]),
                    mon: Number(endTime[1]),
                };

                let ye = endObj.year - startObj.year;
                let mon;

                if (ye !== 0) {
                    mon = endObj.mon + 12 - startObj.mon + 1;
                } else {
                    mon = endObj.mon - startObj.mon + 1;
                }

                let pointer = mon + startObj.mon;
                for (var i = startObj.mon; i < pointer; i++) {
                    let j;
                    let y;
                    let obj = {};
                    if (i > 12 && i < 24) {
                        j = i - 12;
                        y = startObj.year + 1;
                    } else {
                        j = i;
                        y = startObj.year;
                    }

                    obj["year"] = y;
                    obj["mon"] = j;
                    this.monList.push(obj);
                }
                this.setTime(startTime, endTime);

                // console.log(this.monList, "this.monList");
                // let startMonth = new Date(start).getMonth() + 1;
                // let endMonth = new Date(end).getMonth() + 1;
                // console.log(startMonth, endMonth, "月份");
                // this.startMonth = startMonth;
                // this.endMonth = endMonth;
                // this.currentmonth = startMonth;
                let yea = '年',
                    mo = '月';
                checkList.forEach((item) => {
                    this.$set(item, 'time', item.yearMonth.replace(yea,'-').replace(mo,''))
                    let timeArr = item.time.split('-')[1].split('');
                    if (timeArr[0] == '0') {
                        this.$set(item, 'mon', timeArr[1])
                    } else {
                        this.$set(item, 'mon', item.time)
                    }

                    let selTimes = []
                    item.monthDayList.forEach((subItem) => {
                        let obj = {
                            day: subItem.split('-')[1]
                        }

                        selTimes.push(obj)
                    })

                    this.$set(item, 'selTimes', selTimes)
                })

                this.monList.forEach((item) => {
                    item.list.forEach((subItem) => {
                        checkList.forEach((chItem) => {
                            if (item.mon ==  chItem.mon) {
                                chItem.selTimes.forEach((pItem) => {
                                    if (pItem.day == subItem.day) {
                                        subItem.type = true;
                                    }
                                })
                            }
                        })
                    })
                })
                console.log(this.monList,'checkList')
            }
        },
        checkListTime(data) {
            console.log(data, "0000");
        },
        /**
         * @Description: 日历天数处理
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-13 14:56:54
         */
        setTime(startTime, endTime) {
            this.monList.forEach((item, index) => {
                let arr1 = [];
                let day = new Date(item.year, item.mon, 0).getDate();
                console.log(day, "天数");
                for (var i = 1; i <= day; i++) {
                    let obj = {};
                    if (i < 10) {
                        obj["day"] = '0'+i;
                    } else {
                        obj["day"] = i;
                    }

                    obj["type"] = false;
                    arr1.push(obj);
                }
                if (index == 0) {
                    arr1 = arr1.filter((subItem) => {
                        return subItem.day >= startTime[2];
                    });
                }

                if (index == this.monList.length - 1) {
                    arr1 = arr1.filter((subItem) => {
                        return subItem.day <= endTime[2];
                    });
                }
                item.list = arr1;
            });
        },
        handleBack() {
            this.$eventDispatch('setGlobalPageType', 'list')
            this.$emit("handleBack", {
                // typeId: this.detailData.typeId
                // typeId: 'student'
            });
        },
        /**
         * @Description: 查询、重置
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-11 09:38:59
         */
        clickBtn(type) {
            switch (type.item.fn) {
                case "primary": // 查询
                    let status = this.checkTime(this.listQuery.startTime, this.listQuery.endTime)
                    if (status) {
                        this.getList();
                    }
                    break;
                case "reset": //重置
                    this.init();
                    break;
                default:
                    break;
            }
        },
        /**
         * @Description: 选择时间
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-14 16:25:31
         */
         datePickerBlur() {
            let dateArr = this.formData.data.find((item) => {
                return item.key == "dateArr";
            });

            let [startTime, endTime] = dateArr.value ? dateArr.value : ["", ""];
            this.checkTime(startTime, endTime)
            this.listQuery.startTime = startTime;
            this.listQuery.endTime = endTime;

        },
        /**
         * @Description: 时间校验，不能超过6个月
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-14 16:25:09
         */
        checkTime(startTime, endTime){
            let start = new Date(startTime);
            let end = new Date(endTime);
            let status = true
            if(end.getFullYear() != start.getFullYear()){
                if( (end.getMonth()+12 - start.getMonth()) > 6){
                    this.$message.error("开始时间和结束时间不能超过6个月");
                        return status = false
                    }else if( (end.getMonth()+12 - start.getMonth()) == 6){
                        if(end.getDate() > start.getDate()){
                            this.$message.error("开始时间和结束时间不能超过6个月");
                            return  status = false
                        }
                    }
            }
            if( (end.getMonth() - start.getMonth()) > 6){
                this.$message.error("开始时间和结束时间不能超过6个月");
                        return
                    }else if( (end.getMonth() - start.getMonth()) == 6){
                        if(end.getDate() > start.getDate()){
                            this.$message.error("开始时间和结束时间不能超过6个月");
                            return status = false
                        }
                    }

                    return status;

        },
        /**
         * @Description: 展开收起
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-11 09:38:43
         */
        changeBtnFormType() {
            this.formData.btnFormType = !this.formData.btnFormType;
            this.$nextTick(() => {
                this.table_config.height =
                    document.body.clientHeight -
                    document.getElementById("table").offsetTop -
                    62;
            });
        },
    },
};
</script>
<style scoped lang="scss">
.content {
    .content-box {
        width: 100%;
        background-color: #EDF0F7;
        .back-detail {
            padding: 0 10px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            background: #f7f7f7;
            border-radius: 4px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #6d7073;
            display: flex;
            margin-left: 16px;
            justify-content: space-around;
        }

        //筛选
        /deep/ .expand-filter {
            height: 32px;
            padding: 10px !important;
            margin-bottom: 10px !important;
        }

        //日期详情
        .detail {
            padding-left: 20px;
            background-color: #fff;
            .detail-title {
                display: flex;
                text-align: center;
                margin-bottom: 20px;

                .text1 {
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #2b2f33;
                    margin-right: 20px;
                }

                .text2 {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #2b2f33;
                }
            }

            .detail-item {
                display: flex;
                justify-content: left;
                flex-wrap: wrap;

                .waibu {
                    width: 395px;
                    height: 222px;
                    margin: 0 20px 20px 0;
                    border: 1px solid #edf0f2;
                    border-radius: 8px;

                    .title {
                        height: 32px;
                        background: #f7fafc;
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #2b2f33;
                        line-height: 32px;
                        padding-left: 16px;
                    }

                    /* 日历内容 */
                    .box {
                        display: flex;
                        justify-content: inherit;
                        flex-wrap: wrap;

                        .box1 {
                            display: flex;
                            justify-content: center;
                            width: calc(100% / 7);

                            .box-data {
                                box-sizing: border-box;
                                margin-top: 12px;
                                width: 24px;
                                height: 24px;
                                font-size: 12px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #2b2f33;
                                text-align: center;
                                line-height: 24px;
                            }

                            .box-data-selected {
                                background: #e1edfa;
                                border-radius: 50%;
                            }
                        }
                    }
                }
            }
        }
        .global-page-back{
            border-radius: 4px;
            margin-bottom: 10px;
        }
    }
}
</style>
