<!--托管服务-核查记录-按学生t-详情-->
<template>
    <div class="detail-wrap">
        <!-- 按天详情 -->
        <data-details v-if="detailData.typeId === 'data'" :detail-data="detailData" ></data-details>
        <!-- 按学生详情 -->
        <student-detail v-if="detailData.typeId === 'student'" :detail-data="detailData" @handleBack="handleBack"></student-detail>
    </div>
</template>

<script>
import DataDetails from './CheckRecordDetail/DataDetails.vue'
import StudentDetail from './CheckRecordDetail/StudentDetail.vue'
export default {
    name: "CheckRecordDetail",
    props: {
        detailData: {
            type: Object
        },
        type: String
    },
    components: {
        DataDetails,
        StudentDetail
    },
    methods: {
        handleBack() {
          this.$emit('toList', {
            typeId: this.detailData.typeId
            // typeId: 'student'
          })
        },
    }
}
</script>

<style scoped lang="scss">
.detail-wrap {
    box-sizing: border-box;
    background-color: #fff;
    height: 100%;
    overflow: auto;
    border-radius: 4px 4px 0 0 ;
}
</style>
